import React from "react"
import Layout from "../../components/it/layout"
import { Router } from "@reach/router"
import Drive from "../../components/drive"
import Login from "../../components/login"
import PrivateRoute from "../../components/privateRoute"
import SEO from "../../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser, isLoggedIn } from "../../services/auth"
import { navigate } from "gatsby-link"

const App = () => {

  const data = useStaticQuery(graphql`
      query {
          image: file(relativePath: { eq: "app.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  const seo = {
    title: "Dashboard",
    description: "",
  }

  const Default = () => {
    if (isBrowser()) {
      if (isLoggedIn()) navigate(`/app/drive`)
      else navigate(`/app/login`)
    }

    return null;
  }

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} lang={"it"} />
      <Container fluid>
        <Row className={"site-content"}>
          <Col md={12} lg={6} xl={5} className={"offset-lg-1 mb-5"}>
            <Router>
              <PrivateRoute path="/app/drive" component={Drive} />
              <Login path="/app/login" />
              <Default default/>
            </Router>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5} className={"offset-lg-1 p-0"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )

}

export default App
