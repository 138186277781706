import React from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { navigate } from "gatsby-link"
import { handleLogin, isLoggedIn } from "../services/auth"

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    error: ``,
  }
  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = async event => {
    event.preventDefault()
    const state = await handleLogin(this.state);
    if (state.code === 200) navigate(`/app/drive`);
    else {
      this.setState({ error: state.message ?? 'An error happened' })
    }
  }
  render() {
    if (isLoggedIn()) navigate(`/app/drive`)

    return (
      <>
        <h1 className={"mb-4"}>Login</h1>
        <Form method="post" onSubmit={event => this.handleSubmit(event)} style={{ maxWidth: '600px'}}>
          <Form.Group controlId="formUsername">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="text" placeholder="Username" name={"username"} onChange={this.handleUpdate} />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" name={"password"} onChange={this.handleUpdate} />
          </Form.Group>

          { this.state.error ? (
            <p className={"float-left text-danger mt-2"}>{ this.state.error }</p>
          ) : null}
          <Button className={"float-right"} variant="primary" type="submit">
            Login
          </Button>
        </Form>
      </>
    )
  }
}

export default Login
