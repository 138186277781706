import React from "react"
import { fetchDir, fetchFile } from "../services/drive"
import ListGroup from "react-bootstrap/ListGroup"
import Spinner from "react-bootstrap/Spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Drive extends React.Component {
  _isMounted = false;

  state = {
    loading: true,
    current: '',
    directory: []
  }

  handleNavigation = async (path = '') => {
    if (this._isMounted) this.setState({ loading: true })
    const { data } = await fetchDir(path);
    if (this._isMounted) this.setState({
      directory: data,
      current: path,
      loading: false,
    })
  }

  handleDownload = async (path) => {
    if (this._isMounted) this.setState({ loading: true })
    let windowReference = window.open();
    const { data } = await fetchFile(path);
    windowReference.location = data.link;
    // window.open(data.link)
    if (this._isMounted) this.setState({ loading: false })
  }

  getContainingPath = () => {
    return this.state.current.split('/').slice(0, -1).join('/');
  }

  getContainingName = () => {
    return this.state.current.split('/').slice(-2, -1).join('/');
  }

  componentDidMount(){
    this._isMounted = true;
    this.handleNavigation();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <>
        <h1 className={"mb-4"}>Drive</h1>
        { !this.state.loading ? (
          <ListGroup variant="flush" className={"drive"}>
            { this.state.current !== '' ? (
              <ListGroup.Item onClick={() => this.handleNavigation(this.getContainingPath())}><FontAwesomeIcon icon="folder-open" className={"mr-2"} /> ..</ListGroup.Item>
            ) : null }
            { this.state.directory.filter((el) => el.type === 'folder').map((folder, key) => (
              <ListGroup.Item key={key} onClick={() => this.handleNavigation(folder.path_lower)}><FontAwesomeIcon icon="folder" className={"mr-2"} /> { folder.name }</ListGroup.Item>
            ))}
            { this.state.directory.filter((el) => el.type === 'file').map((file, key) => (
              <ListGroup.Item key={key} onClick={() => this.handleDownload(file.path_lower)}><FontAwesomeIcon icon="file" className={"mr-2"} style={{ marginLeft: '2px' }} /> { file.name }</ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <ListGroup variant="flush" className={"drive"}>
            <ListGroup.Item><Spinner animation="border" className={"align-middle mr-2 mb-1"} /> Loading...</ListGroup.Item>
          </ListGroup>
        )}
      </>
    )

  }
}

export default Drive
