import { getToken, logout } from "./auth"
import { navigate } from "gatsby-link"

export const fetchDir = async (path = '') => {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`,
    },
  };
  const response = await fetch(`/.netlify/functions/drive?folder=${path}`, requestOptions);
  const { data, err } = await response.json();

  if (err && err.name ===  'TokenExpiredError') {
    logout(() => navigate(`/app/login`))
  }

  if (data) {

    return {
      code: 200,
      data
    }

  }
  return {
    code: 403,
    message: data.msg
  }
}

export const fetchFile = async (path) => {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`,
    },
  };
  const response = await fetch(`/.netlify/functions/drive?file=${path}`, requestOptions);
  const { data, err } = await response.json();

  if (err && err.name ===  'TokenExpiredError') {
    logout(() => navigate(`/app/login`))
  }

  if (data) {

    return {
      code: 200,
      data
    }

  }
  return {
    code: 403
  }
}
